export const sendID = async (
  baseUrl,
  dni,
  identifier,
  redirect_uri,
  state,
  wallet_id
) => {
  if (!identifier || !redirect_uri || !state || !wallet_id) {
    console.error("Missing query params");
    throw new Error();
  }

  const headers = {};
  headers["Content-Type"] = "application/json";

  return fetch(`${baseUrl}/v1/public/pull-transfer-consent`, {
    method: "POST",
    headers,
    body: JSON.stringify({
      dni,
      identifier,
      wallet_id,
      state,
    }),
  });
};
