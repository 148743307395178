import { Box, Flex, Link, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { FiInfo } from "react-icons/fi";

const HeaderMessage = ({ step }) => (
  <Flex
    align="start"
    alignItems="center"
    background="#FFFAF0"
    color="#966903"
    justifyContent="center"
    p="14px 17px"
    position="relative"
    w="100%"
  >
    <Box>
      <FiInfo />
    </Box>
    <Box ml="9px">
      <Text fontSize="14px" lineHeight="18px">
        Nadie de Brubank va a pedirte que vincules tus cuentas.{" "}
        <Link
          href="https://help.brubank.com/es/"
          isExternal
          textDecor="underline"
        >
          Conocer más
        </Link>
      </Text>
    </Box>
  </Flex>
);

const { number } = PropTypes;

HeaderMessage.propTypes = {
  step: number,
};

HeaderMessage.defaultProps = {
  step: 1,
};

export default HeaderMessage;
