import { Routes as RoutesWrapper, Route } from "react-router-dom";

import { Layout } from "../components";

import CodeInput from "./code-input";
import CodeSent from "./code-sent";
import CodeSentError from "./code-sent-error";
import CodeSentSuccess from "./code-sent-success";
import Home from "./home";
import IdInput from "./id-input";

const Routes = (props) => {
  return (
    <RoutesWrapper {...props}>
      <Route element={<Layout />} path="/">
        <Route element={<Home />} index />
        <Route element={<IdInput />} path="ingresar-dni" />
        <Route element={<CodeSent />} path="codigo-enviado" />
        <Route element={<CodeInput />} path="ingresar-codigo" />
        <Route element={<CodeSentSuccess />} path="cuenta-vinculada" />
        <Route element={<CodeSentError />} path="cuenta-no-vinculada" />
      </Route>
    </RoutesWrapper>
  );
};

export default Routes;
