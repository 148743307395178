import { useLocation, useNavigate } from "react-router-dom";

const useCustomNavigate = () => {
  const defaultNavigate = useNavigate();
  const { search } = useLocation();

  const navigate = (route, param = "") => {
    defaultNavigate(`${route}${search}&${param}`);
  };

  return navigate;
};

export default useCustomNavigate;
