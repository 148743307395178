/* eslint-disable unused-imports/no-unused-vars */
import { Center, Flex } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Flex h="100%" w="100%">
          <Center w="100%">
            <h1 data-testid="error-boundary">
              Algo falló, por favor intentá nuevamente.
            </h1>
          </Center>
        </Flex>
      );
    }

    return this.props.children;
  }
}

const { node } = PropTypes;

ErrorBoundary.propTypes = {
  children: node,
};

export default ErrorBoundary;
