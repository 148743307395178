import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { FiInfo } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const CodeSentError = () => {
  const navigate = useNavigate();
  const handleRetry = () => {
    navigate("/ingresar-codigo");
  };

  return (
    <Flex align="center" className="home" direction="column" flex={1}>
      <Flex align="center" direction="column">
        <Flex
          alignItems="center"
          background="#FFF5F5"
          borderRadius="100px"
          height="62.5px"
          justifyContent="center"
          mt="36px"
          width="62.5px"
        >
          <FiInfo color="#DC4342" fontSize="32px" />
        </Flex>
        <Text fontSize="24px" mt="8px">
          No logramos vincular tus cuentas
        </Text>
        <Text
          color="#797979"
          fontSize="16px"
          letterSpacing="-0.5px"
          m="8px 12px 0px 12px"
          textAlign="center"
        >
          Hubo un error en el proceso y no pudimos vincular{" "}
          <Box display={{ base: "block", sm: "none" }} h="0">
            {/* needed for add a line space when mobile*/}
            <br />
          </Box>
          tus cuentas. Podes volver a intentarlo
        </Text>
      </Flex>

      <Flex
        alignItems="stretch"
        color="#966903"
        direction="column"
        flex={1}
        justifyContent={{ base: "end", sm: "start" }}
        p="24px"
        position="relative"
        w={{ base: "100%", sm: "350px" }}
      >
        <Button
          background="#614AD9"
          color="white"
          colorScheme="purple"
          h={50}
          mb="14px"
          onClick={handleRetry}
        >
          Volver a intentar
        </Button>
      </Flex>
    </Flex>
  );
};

export default CodeSentError;
