import { Box, Button, Flex, Text } from "@chakra-ui/react";

import ShieldIcon from "./shield-icon";

const CodeSentSuccess = () => {
  const handleGoToLinkedAccounts = () => {
    // TODO: Pendiente ver como abrir el listado de cuentas vinculadas en la app
  };

  return (
    <Flex align="center" className="home" direction="column" flex={1}>
      <Flex align="center" direction="column">
        <Flex
          alignItems="center"
          background="#4ad9721f"
          borderRadius="100px"
          height="62.5px"
          justifyContent="center"
          mt="36px"
          width="62.5px"
        >
          <ShieldIcon />
        </Flex>
        <Text fontSize="26px" mt="8px">
          Vinculaste tus cuentas
        </Text>
        <Text
          color="#797979"
          fontSize="16px"
          m="8px 24px 0px 24px"
          textAlign="center"
        >
          Tu cuenta de Brubank fue{" "}
          <Box display={{ base: "block", sm: "none" }} h="0">
            {/* needed for add a line space when mobile*/}
            <br />
          </Box>
          asociada a [Wallet/Financiera]
        </Text>
      </Flex>

      <Flex
        alignItems="stretch"
        color="#966903"
        direction="column"
        flex={1}
        justifyContent={{ base: "end", sm: "start" }}
        p="24px"
        position="relative"
        w={{ base: "100%", sm: "350px" }}
      >
        <Button
          background="#614AD9"
          color="white"
          colorScheme="purple"
          h={50}
          mb="14px"
          onClick={handleGoToLinkedAccounts}
        >
          Ir a mis cuentas vinculadas
        </Button>
      </Flex>
    </Flex>
  );
};

export default CodeSentSuccess;
