/**
 * Determine if it is in a mobile.
 *
 * @returns {boolean}
 */
export const isMobile = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  return /windows phone|android|iPad|iPhone|iPod/i.test(userAgent);
};
