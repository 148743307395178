import {
  Box,
  Button,
  Flex,
  HStack,
  PinInput,
  PinInputField,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { FiAlertCircle, FiCopy } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";

import { HeaderMessage } from "../../components";
import { useConfig } from "../../context/config";

import { sendCode } from "./code-input.api";

const inputStyle = {
  height: "76px",
  borderRadius: "16px",
  fontSize: "30px",
  fontWeight: 500,
  _focus: {
    borderColor: "#614AD9",
  },
};

const CodeInput = () => {
  const { baseUrl } = useConfig();

  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const dni = searchParams.get("dni");
  const identifier = searchParams.get("identifier");
  const redirect_uri = searchParams.get("redirect_uri");
  const state = searchParams.get("state");
  const wallet_id = searchParams.get("wallet");

  const handleSendCode = async () => {
    try {
      setIsLoading(true);
      const response = await sendCode(
        baseUrl,
        value,
        dni,
        identifier,
        redirect_uri,
        state,
        wallet_id
      );

      if (response.status === 200) {
        const data = await response.json();
        if (!data?.redirect) {
          console.error("No url for redirect", response);
          throw new Error();
        }
        window.location.replace(data.redirect);
      } else if (response.status === 401) {
        // Token invalido
        const data = await response.json();
        if (!data?.redirect_uri) {
          console.error("No url for redirect", response);
          throw new Error();
        }
        window.location.replace(data.redirect_uri);
      } else {
        console.error("Response not expected", response);
        throw new Error();
      }
      setIsLoading(false);
    } catch (error) {
      setHasError(true);
      setIsLoading(false);
      console.error(error);
    }
  };

  const handlePaste = () => {
    navigator.clipboard.readText().then((clipText) => {
      // Valida que sea un numero
      if (/^\d+$/.test(clipText)) setValue(clipText);
    });
  };

  return (
    <Flex alignItems="center" direction="column" flex={1}>
      <HeaderMessage />

      <Flex align="center" direction="column" m="36px 24px 0px 24px">
        <Text
          fontSize={{ base: "25px", sm: "40px" }}
          fontWeight="400"
          letterSpacing="-0.5px"
          lineHeight={{ base: "32px", sm: "42px" }}
          textAlign="center"
        >
          Ingresá el código de seguridad
        </Text>
        <Text color="#797979" fontSize="18px" mt="8px" textAlign="center">
          Ingresá o pegá el código de seguridad para vincular tus cuentas.
        </Text>

        <Box mt={34}>
          <Flex alignItems="center" flexDir={{ base: "column", sm: "row" }}>
            <HStack
              mb={{ base: "16px", sm: "0" }}
              mr={{ base: "0", sm: "20px" }}
              sx={{ input: inputStyle }}
            >
              <PinInput
                isDisabled={isLoading}
                isInvalid={hasError}
                onChange={setValue}
                placeholder=""
                size="lg"
                value={value}
              >
                <PinInputField autoFocus />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
            <Button
              background="#F3F1FC"
              color="#614AD9"
              height="48px"
              onClick={handlePaste}
            >
              <Text mr="6px">Pegar</Text>
              <FiCopy />
            </Button>
          </Flex>

          {hasError && (
            <Flex
              alignItems="center"
              color="#EC4F4F"
              justifyContent="center"
              mt="16px"
            >
              <FiAlertCircle fontSize={18} />
              <Text letterSpacing="-0.5px" ml="8px">
                El código es incorrecto, volvé a intentarlo.
              </Text>
            </Flex>
          )}
        </Box>
      </Flex>

      <Flex
        alignItems="stretch"
        color="#966903"
        direction="column"
        flex={1}
        justifyContent={{ base: "end", sm: "start" }}
        p="37px 24px 16px 24px"
        position="relative"
        w={{ base: "100%", sm: "484px" }}
      >
        <Button
          background="#614AD9"
          color="white"
          colorScheme="purple"
          h={50}
          isDisabled={value.length < 6}
          isLoading={isLoading}
          onClick={handleSendCode}
        >
          Vincular cuentas
        </Button>
      </Flex>
    </Flex>
  );
};

export default CodeInput;
