import { Button, Flex, Text } from "@chakra-ui/react";

import { HeaderMessage } from "../../components";
import useCustomNavigate from "../../hooks/useCustomNavigate";
import ShieldIcon from "../code-sent-success/shield-icon";

const Home = () => {
  const navigate = useCustomNavigate();

  const handleNext = () => {
    navigate("/ingresar-dni");
  };

  return (
    <Flex align="center" className="home" direction="column" flex={1}>
      <HeaderMessage />

      <Flex align="center" direction="column" m="36px 20px 0px 20px">
        <Flex
          alignItems="center"
          background="#4ad9721f"
          borderRadius="100px"
          height={{ base: "62.5px", sm: "100px" }}
          justifyContent="center"
          mt={{ base: "0", sm: "36px" }}
          width={{ base: "62.5px", sm: "100px" }}
        >
          <ShieldIcon />
        </Flex>

        <Flex
          direction="column"
          m="36px 15px 0px 15px"
          textAlign="center"
          width={{ base: "100%", sm: "407px" }}
        >
          <Text
            fontSize={{ base: "26px", sm: "40px" }}
            fontWeight="400"
            lineHeight={{ base: "32px", sm: "42px" }}
            textAlign="center"
          >
            Vinculación de cuentas
          </Text>

          <Text
            color="#797979"
            fontSize={{ base: "16px", sm: "18px" }}
            letterSpacing="-0.6px"
            mt="8px"
          >
            Al vincular tus cuentas, vas a autorizar el envío de dinero desde tu
            cuenta de Brubank hacia la cuenta de banco o billetera virtual de la
            que viniste.
          </Text>
        </Flex>
      </Flex>

      <Flex
        alignItems="stretch"
        color="#966903"
        direction="column"
        flex={1}
        justifyContent={{ base: "end", sm: "start" }}
        p="24px 24px 16px 24px"
        position="relative"
        w={{ base: "100%", sm: "408px" }}
      >
        <Button
          background="#614AD9"
          color="white"
          colorScheme="purple"
          onClick={handleNext}
        >
          Vincular mis cuentas
        </Button>
      </Flex>
    </Flex>
  );
};

export default Home;
