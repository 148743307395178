import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";

import Logo from "../logo";

const Layout = () => {
  return (
    <Flex
      bg="white"
      className="layout"
      color="black"
      direction="column"
      h="100%"
    >
      <Flex
        align="start"
        alignItems="center"
        borderBottom="1px"
        borderColor="#E7E7E7"
        justify="space-between"
        p="24px 37px 24px 40px"
      >
        <Box mr="10px">
          <Logo w="101px" />
        </Box>
        <Box h="16px" mr={{ base: "0", sm: "45%" }}>
          <Text color="#614AD9">Vincular cuentas</Text>
        </Box>
      </Flex>
      <Flex background="#FCFCFC" className="outlet" direction="column" flex={1}>
        <Outlet />
      </Flex>
    </Flex>
  );
};

export default Layout;
