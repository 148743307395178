import PropTypes from "prop-types";
import { createContext, useContext } from "react";

import { extractConfig } from "./extract-config";

const ConfigContext = createContext(null);

export const useConfig = () => useContext(ConfigContext);

export const withConfig = (Component) => (props) => {
  const config = useConfig();

  return <Component {...props} config={config} />;
};

export const ConfigProvider = ({ children }) => {
  const config = extractConfig(
    {
      isProduction: {
        from: "NODE_ENV",
        parser: (value) => value === "production",
      },
      baseUrl: { from: "BASE_URL", required: true },
      bruAppBaseUrl: { from: "BRU_APP_BASE_URL", required: true },
    },
    [
      // These sources are read in order.
      // Additional sources can be added to quickly change configurations
      // whitout having to restart the dev server.
      {
        source: process.env,
        keyTransform: (key) => (key !== "NODE_ENV" ? `REACT_APP_${key}` : key),
      },
      // Read config from window.SERVER_DATA (for deployed apps):
      {
        source: window.SERVER_DATA ? JSON.parse(window.SERVER_DATA) : {},
      },
    ]
  );

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

ConfigProvider.propTypes = {
  children: PropTypes.node,
};
