import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";

import { HeaderMessage } from "../../components";
import { useConfig } from "../../context/config";
import useCustomNavigate from "../../hooks/useCustomNavigate";

import { sendID } from "./id-input.api";

const IdInput = () => {
  const { baseUrl } = useConfig();

  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isDisallowedIdError, setIsDisallowedIdError] = useState(false);
  const [isInputActive, setIsInputActive] = useState(false);
  const navigate = useCustomNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const identifier = searchParams.get("identifier");
  const redirect_uri = searchParams.get("redirect_uri");
  const state = searchParams.get("state");
  const wallet_id = searchParams.get("wallet");

  const handleChange = (event) => {
    const newValue = event.target.value;

    // maxLength not working for input with type number
    if (newValue.length < 9) {
      setValue(newValue);
    }
  };

  const handleSendID = async () => {
    try {
      setIsLoading(true);
      const response = await sendID(
        baseUrl,
        value,
        identifier,
        redirect_uri,
        state,
        wallet_id
      );
      if (response?.ok) {
        navigate("/codigo-enviado", `dni=${value}`);
        setHasError(false);
        setIsDisallowedIdError(false);
      } else {
        console.error("Fail to send DNI. ", response?.statusText);
        setHasError(true);
        if (response?.status === 403) {
          setIsDisallowedIdError(true);
        }
      }
    } catch (err) {
      console.error("Fail to send DNI. ", err);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex align="center" direction="column" flex={1}>
      <HeaderMessage />

      <Flex align="center" direction="column" m="36px 24px 0px 24px">
        <Text
          fontSize={{ base: "26px", sm: "40px" }}
          fontWeight="400"
          lineHeight={{ base: "32px", sm: "42px" }}
          textAlign="center"
          width={{ base: "320px", sm: "499px" }}
        >
          Ingresá tu DNI para vincular tu cuenta a Brubank
        </Text>
        <Text
          color="#797979"
          fontSize="18px"
          fontWeight="400"
          letterSpacing="-1px"
          mt="8px"
        >
          Asegurate de ingresar solo números.
        </Text>
        <Box
          background="white"
          border="2px solid #F0F0F0"
          borderColor={hasError ? "#DC4342" : "inherit"}
          borderRadius="16px"
          mt="28px"
          w="327px"
          style={
            isInputActive
              ? { borderColor: "#614AD9", boxShadow: "0 0 0 1px #614AD9" }
              : {}
          }
        >
          <Flex direction="column" m="16px 24px">
            <Text color="#797979" fontWeight="400" lineHeight="16px" mb="8px">
              DNI
            </Text>
            <Input
              _focusVisible={{ border: "none", boxShadow: "none" }}
              _placeholder={{ color: "#AEAEAE" }}
              border="none"
              fontSize="18px"
              h="20px"
              inputMode="numeric"
              lineHeight="20px"
              onBlur={() => setIsInputActive(false)}
              onChange={handleChange}
              onFocus={() => setIsInputActive(true)}
              onWheel={(e) => e.target.blur()}
              p="0"
              placeholder="41537932"
              type="number"
              value={value}
              onKeyDown={(event) =>
                ["e", "E", "+", "-", "."].includes(event.key) &&
                event.preventDefault()
              }
            />
          </Flex>
        </Box>
      </Flex>

      {hasError && (
        <Flex alignItems="center" mt="9px">
          <FiAlertCircle color="#EC4F4F" />
          <Text color="#EC4F4F" letterSpacing="-1px" ml="9px" w="327px">
            {isDisallowedIdError
              ? "Lo sentimos, esta funcionalidad todavía no está disponible para tu cuenta. Volvé a intentarlo más adelante."
              : "Lo sentimos, ocurrió un error, volvé a intentarlo."}
          </Text>
        </Flex>
      )}

      <Flex
        alignItems="stretch"
        color="#966903"
        direction="column"
        flex={1}
        justifyContent={{ base: "end", sm: "start" }}
        p="24px 24px 16px 24px"
        position="relative"
        w={{ base: "100%", sm: "375px" }}
      >
        <Button
          background="#614AD9"
          color="white"
          colorScheme="purple"
          isDisabled={value.length < 6}
          isLoading={isLoading}
          onClick={handleSendID}
        >
          Continuar
        </Button>
      </Flex>
    </Flex>
  );
};

export default IdInput;
