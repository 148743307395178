import { Image, LinkBox, LinkOverlay } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

import logo from "../../assets/logo/brubanktext.svg";

const Logo = (props) => {
  return (
    <LinkBox {...props} className="logo">
      <LinkOverlay as={NavLink} to="/">
        <Image src={logo} width="150px" />
      </LinkOverlay>
    </LinkBox>
  );
};

export default Logo;
