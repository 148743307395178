import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { Fragment } from "react";
import { FiSmartphone } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";

import { HeaderMessage } from "../../components";
import { useConfig } from "../../context/config";
import useCustomNavigate from "../../hooks/useCustomNavigate";
import { isMobile } from "../../utils";

const CodeSent = () => {
  const navigate = useCustomNavigate();
  const { bruAppBaseUrl } = useConfig();

  const [searchParams, setSearchParams] = useSearchParams();
  const wallet_id = searchParams.get("wallet");

  const handleOpenApp = () => {
    window.open(
      `${bruAppBaseUrl}/app/link-account?wallet_id=${wallet_id}`,
      "_self",
      "noreferrer"
    );
  };

  const handleAlreadyHaveCode = () => {
    navigate("/ingresar-codigo");
  };

  return (
    <Flex alignItems="center" direction="column" flex={1}>
      <HeaderMessage />

      <Flex align="center" direction="column">
        <Flex
          alignItems="center"
          background="#F3F1FC"
          borderRadius="100px"
          height="62.5px"
          justifyContent="center"
          mt="36px"
          width="62.5px"
        >
          <FiSmartphone color="#614AD9" fontSize="25px" />
        </Flex>
        <Text fontSize="26px" mt="8px">
          Abrí la app de Brubank
        </Text>
        <Box
          color="#797979"
          fontSize="16px"
          p="8px 24px 0px 24px"
          textAlign="center"
          w={{ base: "100%", sm: "424px" }}
        >
          Para continuar con el proceso, ingresá a la app de Brubank y dirigite
          a la sección{" "}
          <strong>Mi Cuenta - Seguridad - Cuentas vinculadas</strong> para
          acceder al código.
        </Box>
      </Flex>

      <Flex
        alignItems="stretch"
        color="#966903"
        direction="column"
        flex={1}
        justifyContent={{ base: "end", sm: "start" }}
        p="24px"
        position="relative"
        w={{ base: "100%", sm: "350px" }}
      >
        {isMobile() && (
          <Fragment>
            <Button
              background="#614AD9"
              color="white"
              colorScheme="purple"
              mb="14px"
              onClick={handleOpenApp}
            >
              Ver código en Brubank
            </Button>
            <Button
              background="#EEECFD"
              color="#614AD9"
              onClick={handleAlreadyHaveCode}
            >
              Ya tengo código
            </Button>
          </Fragment>
        )}
        {!isMobile() && (
          <Button
            background="#614AD9"
            color="white"
            colorScheme="purple"
            mb="14px"
            onClick={handleAlreadyHaveCode}
          >
            Ya tengo código
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default CodeSent;
