export const sendCode = async (
  baseUrl,
  token,
  dni,
  identifier,
  redirect_uri,
  state,
  wallet_id
) => {
  const headers = {};
  headers["Content-Type"] = "application/json";

  return fetch(`${baseUrl}/v1/public/pull-transfer-consent/confirmation`, {
    method: "POST",
    headers,
    body: JSON.stringify({
      dni,
      token,
      identifier,
      redirect_uri,
      state,
      wallet_id,
    }),
  });
};
