import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import { ErrorBoundary } from "./components";
import { ConfigProvider } from "./context/config";
import Routes from "./routes";

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <ErrorBoundary>
        <BrowserRouter basename="/">
          <ConfigProvider>
            <Routes />
          </ConfigProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </ChakraProvider>
  </React.StrictMode>
);
