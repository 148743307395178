const ShieldIcon = () => (
  <svg
    height="30px"
    version="1.1"
    viewBox="0 0 40 44"
    width="30px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      id="✅-Handoff-23/02-V.1"
      stroke="none"
      strokeWidth="1"
    >
      <g
        fill="#37B85B"
        fillRule="nonzero"
        id="Icons"
        transform="translate(-344.000000, -425.000000)"
      >
        <g id="Frame-48096051" transform="translate(96.051636, 425.787170)">
          <g id="shield-check" transform="translate(248.000000, 0.015555)">
            <path d="M19.5,42.9841156 C19.3399981,42.9841156 19.179877,42.9579906 19.025877,42.9059906 C15.8478774,41.8459907 0,35.7279968 0,19.2599964 L0,7.48411751 C0,6.77011752 0.504054616,6.15190266 1.20605457,6.01390266 C10.9660538,4.06190264 14.4261041,2.34610565 18.8081036,0.172105625 C19.2301036,-0.0378943682 19.7899121,-0.0657263994 20.2119121,0.144273594 C24.5339117,2.32227357 27.9478961,4.04390264 37.7958947,6.01390266 C38.4978947,6.15390266 39.0019493,6.77011752 39.0019493,7.48411751 L39.0019493,19.2619495 C39.0019493,35.72995 23.1540719,41.8479438 19.9760723,42.9079437 C19.8200723,42.9579437 19.6599981,42.9841156 19.5,42.9841156 Z M3,8.7082386 L3,19.2599964 C3,32.989996 15.8359984,38.5802718 19.5,39.8942719 C23.1639978,38.5802718 36,32.9879961 36,19.2599964 L36,8.7082386 C27.1579973,6.85823858 23.4397829,5.12189853 19.5297832,3.15989852 C15.3757838,5.21389842 11.7699993,6.87623858 3,8.7082386 Z M18.5839826,26.5441761 L26.5839818,18.5441761 C27.1699817,17.9581761 27.1699817,17.0081058 26.5839818,16.4221058 C25.9979818,15.8361058 25.0479116,15.8361058 24.4619117,16.4221058 L17.5219709,23.3620472 L14.5820298,20.4221058 C13.9960299,19.8361058 13.0459597,19.8361058 12.4599597,20.4221058 C11.8739598,21.0081058 11.8739598,21.9581761 12.4599597,22.5441761 L16.4599593,26.5441761 C16.7519593,26.8361761 17.1360179,26.9841175 17.5200178,26.9841175 C17.9040178,26.9841175 18.2919826,26.8381761 18.5839826,26.5441761 Z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ShieldIcon;
